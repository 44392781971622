import React, { Component } from 'react';
import Session from './Session';
import Select from 'react-select';

import './Landing.scss';

class Landing extends Component {
  state = {
    selectedOption: null
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  theme = theme => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#f7ad0d',
        primary: '#343a40'
      }
    };
  };

  renderSessionDropDown = sessions => {
    const options = [];
    const { selectedOption } = this.state;

    if (sessions.length > 1) {
      let id;
      let status;
      sessions.map(session => {
        id = session.id;
        status = session.status.replace(/_/g, ' ');
        let info = {
          value: id,
          label: `Session ID: ${id} - Status: ${status}`
        };
        return options.push(info);
      });
      return (
        <>
          <Select
            className='Landing-content__dropdown'
            theme={this.theme}
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
            placeholder='Please Select A Session'
            autoFocus
            isSearchable
            classNamePrefix='react-select'
          />
          <Session selectedOption={selectedOption} />
        </>
      );
    } else {
      return (
        <>
          <Session />
        </>
      );
    }
  };
  render() {
    return (
      <main className='Landing'>
        <section className='Landing-content__block'>
          <h1 className='Landing-content__header'>
            Welcome To The Landing Page
          </h1>
          <p className='Landing-content__lead'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Consequuntur sit soluta exercitationem sint architecto atque
            delectus. Velit, tenetur! Sed harum temporibus quibusdam voluptates
            enim quisquam obcaecati, dolorem, in culpa vero blanditiis iste, vel
            veritatis nisi perspiciatis quidem voluptatum? Laboriosam, ut?
          </p>
          {this.renderSessionDropDown(this.props.sessions)}
        </section>
      </main>
    );
  }
}

export default Landing;
