import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './SessionHeader.scss';

export default class SessionHeader extends Component {
  renderDate = () => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const date = new Date(this.props.sessionInfo.date);
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year} `;
  };

  renderHeader = () => {
    if (this.props.showSession !== false) {
      return (
        <section className='session-header'>
          <h1 className='session-header__lead'>Session Proofing</h1>
          <div className='break' />
          <div className='session-header-content__container'>
            <p className='session-header-content'>
              Status:
              <span className='session-header-content__status'>
                {this.props.sessionInfo.status.replace(/_/g, ' ')}
              </span>
            </p>
            <p className='session-header-content'>
              Date:
              <span className='session-header-content__date'>
                {this.renderDate()}
              </span>
            </p>
          </div>
        </section>
      );
    }
    return (
      <main className='no-session-header'>
        <div className='no-session-header-content__container'>
          <div className='no-session-header-content'>
            <h1 className='no-session-header__lead'>Sorry...</h1>
            <p>It seems that we don't have any sessions to show you.</p>
            <p>
              Please check back soon, or feel free to
              <Link to='/contact'> contact us</Link>
            </p>
          </div>
          <div className='no-session-header__image-wrapper'>
            <img
              src='https://pasttimephotographybackend.com/uploads/backend/originals/a9a6b090-f2f6-43b2-ad37-1ce41b2a8543.png'
              className='no-session-header__image'
              alt='polaroid of sad baby'
            />
          </div>
        </div>
      </main>
    );
  };

  render() {
    return this.renderHeader();
  }
}
