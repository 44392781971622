import React, { Component } from 'react';
import RegisterForm from '../../components/Form/RegisterForm';
import Container from '../../components/Container/Container';
import './RegisterPage.scss';

class RegisterPage extends Component {
  render() {
    return (
      <section className='Register'>
        <div className='register-page-background' />
        <Container>
          <RegisterForm />
        </Container>
      </section>
    );
  }
}

export default RegisterPage;
