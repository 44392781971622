import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ContactCTA.scss';

class ContactCTA extends Component {
  render() {
    return (
      <section className='contact-cta'>
        <div className='contact-cta__container'>
          <div className='contact-cta__bgimage' />
          <div className='contact-cta__card'>
            <h2 className='contact-cta-card__lead'>Want to create a memory?</h2>
            <div className='divider' />
            <p className='contact-cta-card__content'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Aspernatur, id quod vitae hic quo voluptatum consequuntur harum
              cumque ea doloribus.
            </p>
            <div className='btn-container'>
              <Link to='/contact'>
                <div className='btn btn-dark'>Book Today</div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactCTA;
