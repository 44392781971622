import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.scss';
import Button from '../../components/Button/Button';
import ScrollIcon from '../../components/ScrollIcon/ScrollIcon';
import Card2Box from '../../components/Card2Box/Card2Box';
import ThreeBox from '../../components/ThreeBox/ThreeBox';
import ContactCTA from '../../components/ContactCTA/ContactCTA';

class HomePage extends Component {
  render() {
    return (
      <div className='homepage-wrapper'>
        <main className='HomePage landing'>
          <div className='dark-overlay'>
            <div className='landing-inner'>
              <h1 className='large'>PAST TIME PHOTOGRAPHY</h1>
              <p className='lead'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                nec felis nulla. Nam libero ante, fringilla in fringilla nec,
                dapibus.
              </p>
              <div className='buttons'>
                <Link to='/portfolio'>
                  <Button className='btn-transparent' title='View Our Work' />
                </Link>
              </div>
            </div>
          </div>
          <ScrollIcon />
        </main>
        <Card2Box />
        <Card2Box layout='reversed' />
        <ThreeBox />
        <ContactCTA />
      </div>
    );
  }
}

export default HomePage;
