import React from 'react';

const CopyRight = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <p className='CopyRight'>&copy; {`${year}, Past Time Photography `}</p>
    </>
  );
};

export default CopyRight;
