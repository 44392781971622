import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import ReduxToastR from 'react-redux-toastr';

import PrivateRoute from '../components/PrivateRoute/PrivateRoute';

import history from '../history';
import HomePage from '../views/Home/HomePage';
import AboutPage from '../views/About/AboutPage';
import ContactPage from '../views/Contact/ContactPage';
import PortfolioPage from '../views/Portfolio/PortfolioPage';
import LoginPage from '../views/Login/LoginPage';
import RegisterPage from '../views/Register/RegisterPage';
import MyAccountPage from '../views/MyAccount/MyAccountPage';
import Navbar from '../components/Navbar/Navbar';
import SideDrawer from '../components/SideDrawer/SideDrawer';
import Custom404 from './Custom404/Custom404';
import Backdrop from '../components/Backdrop/Backdrop';
import Footer from '../components/Footer/Footer';
import PrivacyPage from './Privacy/PrivacyPage';
import './App.scss';

export default class App extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return {
        sideDrawerOpen: !prevState.sideDrawerOpen
      };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backDrop;

    if (this.state.sideDrawerOpen) {
      backDrop = <Backdrop click={this.backdropClickHandler} />;
    }

    return (
      <Router history={history}>
        <ReduxToastR />
        <div className='site'>
          <main className='site-content'>
            <Navbar drawerClickHandler={this.drawerToggleClickHandler} />
            <SideDrawer show={this.state.sideDrawerOpen} />
            {backDrop}
            <Switch>
              <Route path='/' exact strict component={HomePage} />
              <Route path='/about' exact strict component={AboutPage} />
              <Route path='/contact' exact strict component={ContactPage} />
              <Route path='/portfolio' exact strict component={PortfolioPage} />
              <Route path='/login' exact strict component={LoginPage} />
              <Route path='/register' exact strict component={RegisterPage} />
              <Route path='/privacy' exact strict component={PrivacyPage} />
              <PrivateRoute
                path='/account/:customerId'
                exact
                strict
                component={MyAccountPage}
              />
              <Route component={Custom404} />
            </Switch>
          </main>
          <Footer history={history} />
        </div>
      </Router>
    );
  }
}
