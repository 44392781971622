import React from 'react';
import './DrawerToggleButton.scss';

const DrawerToggleButton = props => {
  return (
    <button className='DrawerToggleButton' onClick={props.click}>
      <div className='DrawerToggleButton__line' />
      <div className='DrawerToggleButton__line' />
      <div className='DrawerToggleButton__line' />
    </button>
  );
};

export default DrawerToggleButton;
