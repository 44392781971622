import {
  FETCH_USER_SESSIONS,
  SET_IMAGE_REQUEST,
  REMOVE_IMAGE_REQUEST
} from '../actions/types';

const INTIAL_STATE = {
  sessions: []
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_SESSIONS:
      return { ...state, sessions: action.payload };
    case SET_IMAGE_REQUEST:
      return { ...state, sessions: action.payload };
    case REMOVE_IMAGE_REQUEST:
      return { ...state, sessions: action.payload };
    default:
      return state;
  }
};
