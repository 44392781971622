import React from 'react';
import './Button.scss';

const Button = props => {
  return (
    <button className={`btn ${props.className}`} type={props.type}>
      {props.title}
    </button>
  );
};

export default Button;
