import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import './Navbar.scss';
import Logo from '../Logo/Logo';
import SocialIcons from '../SocialIcons/SocialIcons';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import Auth from '../Auth/auth';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolled: false,
      /**
       * setting initial location like this to avoid visual
       *  jump on nav change
       * //TODO see if there is a more elegant way to do this
       * */

      userLocation: window.location.pathname
    };
  }

  navBarScroll = () => {
    const isTop = window.scrollY < 100;

    if (isTop !== true) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  setUserLocation = pathname => {
    this.setState({ userLocation: pathname });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.navBarScroll);
    //listen for route changes
    //TODO this fires quite often on scroll. Make sure it does not impact performance
    this.props.history.listen(() => {
      this.setUserLocation(window.location.pathname);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.navBarScroll);
  }

  render() {
    return (
      <nav
        className={
          this.state.scrolled || this.state.userLocation !== '/'
            ? 'Navbar navbar-sm'
            : 'Navbar bg-dark'
        }
      >
        <div
          className={
            this.state.scrolled || this.state.userLocation !== '/'
              ? 'navbar-top__links navbar-sm'
              : 'navbar-top__links'
          }
        >
          <div className='navbar-top__icons'>
            <SocialIcons size='lg' />
          </div>
          <div className='navbar-top__logo'>
            <Logo />
          </div>
          <div className='navbar-top__menu'>
            <DrawerToggleButton click={this.props.drawerClickHandler} />
          </div>
        </div>
        <ul className='navbar__links'>
          <li>
            <NavLink
              to='/'
              className='navbar__link'
              activeClassName='navbar__link--active'
              exact
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/about'
              className='navbar__link'
              activeClassName='navbar__link--active'
              exact
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/contact'
              className='navbar__link'
              activeClassName='navbar__link--active'
              exact
            >
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/portfolio'
              className='navbar__link'
              activeClassName='navbar__link--active'
              exact
            >
              Portfolio
            </NavLink>
          </li>
          <Auth
            className='navbar__link'
            activeClassName='navbar__link--active'
          />
        </ul>
      </nav>
    );
  }
}

export default withRouter(Navbar);
