import React from 'react';
import './SocialIcons.scss';

const SocialIcons = props => {
  const { size } = props;
  return (
    <div className='social-icons__list'>
      <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
        <i
          className={`fab fa-facebook-square fa-${size} social-icon__facebook`}
        />
      </a>

      <a
        href='https://www.instagram.com/?hl=en'
        target='_blank'
        rel='noopener noreferrer'
      >
        <i className={`fab fa-instagram fa-${size} social-icon__instagram`} />
      </a>

      <a
        href='https://www.pinterest.com/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <i
          className={`fab fa-pinterest-square fa-${size} social-icon__pinterest `}
        />
      </a>
    </div>
  );
};

export default SocialIcons;
