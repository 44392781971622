import {
  TRY_SIGN_IN,
  TRY_SIGN_OUT,
  REGISTER_USER,
  FETCH_USER,
  FETCH_AUTH_STATUS
} from '../actions/types';

const INTIAL_STATE = {
  isSignedIn: null,
  userId: null,
  token: null
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case TRY_SIGN_OUT:
      return { ...state, isSignedIn: false, userId: null, token: null };
    case TRY_SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userId: action.payload.user.id,
        token: action.payload.token
      };
    case REGISTER_USER:
      return { ...state, isSignedIn: false, userId: action.payload.id };
    case FETCH_AUTH_STATUS:
      return { ...state, isSignedIn: true, userId: action.payload };
    case FETCH_USER:
      return { ...state, userid: action.payload.id };
    default:
      return state;
  }
};
