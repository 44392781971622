import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Container from '../../components/Container/Container';
import './Custom404.scss';

export default class Custom404 extends Component {
  render() {
    return (
      <Container>
        <section className='Custom404'>
          <div className='custom404_landing'>
            <div className='custom404_landing_content__header'>
              <h1>404 Page Not Found</h1>
            </div>
            <div className='custom404_landing_content'>
              <h2 className='custom404_landing_conten__lead'>
                Have You Ever Just Had One Of Those Days?
              </h2>
              <p className='custom404_landing_content__lead'>
                It seems like we couldnt find what you were looking for.
              </p>
            </div>
            <div
              className='custom404_landing_image'
              role='img'
              aria-label='404 image'
            ></div>
            <p className='custom404_landing_content_footer'>
              Would like to see some of our work instead. Visit our{' '}
              <Link to='/portfolio'>Portfolio</Link> or head back{' '}
              <Link to='/'>Home</Link>
            </p>
          </div>
        </section>
      </Container>
    );
  }
}
