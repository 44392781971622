import React, { Component } from 'react';
// import Input from './Input';
// import Button from '../Button/Button';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/index.js';

import './RegisterForm.scss';

//TODO figure out how to combine Login and Register

class RegisterForm extends Component {
  renderError = ({ touched, error }) => {
    if (touched && error) {
      return (
        <div className='error'>
          <span className='error-msg'>{error}</span>
        </div>
      );
    }
  };

  renderInput = ({ input, label, type, meta }) => {
    return (
      <div className='form-name-section'>
        <input
          {...input}
          type={type}
          placeholder=' '
          autoComplete='off'
          className='form-input'
        />
        {this.renderError(meta) ? (
          <label className='form-label-name error'>
            <span className='content-name error'>{label}</span>
          </label>
        ) : (
          <label className='form-label-name'>
            <span className='content-name'>{label}</span>
          </label>
        )}
      </div>
    );
  };

  onSubmit = formValues => {
    this.props.registerUser(formValues);
  };

  render() {
    return (
      //TODO make this reusable
      /**
       * Need to find a way to reuse this for register and forgot
       * password
       */
      <div className='register-form-wrapper'>
        <div className='form-top'>
          <h2 className='form-top__header'>Past Time Photography</h2>
          <p className='form-top__content'>Welcome to our site</p>
        </div>
        <form
          className='Form'
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name='email'
            component={this.renderInput}
            label='Enter Email'
            type='email'
          />
          <Field
            name='password'
            component={this.renderInput}
            label='Enter Password'
            type='password'
          />
          <Field
            name='first_name'
            component={this.renderInput}
            label='Enter First Name'
            type='text'
          />
          <Field
            name='last_name'
            component={this.renderInput}
            label='Enter Last Name'
            type='text'
          />
          <div className='forgot-password'></div>
          <div className='form-bottom'>
            <button
              className='btn btn-dark primary-button'
              type='submit'
              disabled={this.props.submitting}
            >
              Register
            </button>
            <div className='seperator'>
              <hr className='seperator-line' />
              <span className='seperator-content'>or</span>
              <hr className='seperator-line' />
            </div>
            <div className='form-bottom-cta'>
              <p className='form-bottom-cta__content'>
                Already Have An Account?
              </p>
              <Link to={'/login'}>Sign In</Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const validate = formValues => {
  //TODO set timeout for all errors
  //TODO centralize errors remove logic from here
  const errors = {};
  if (!formValues.email) {
    errors.email = 'Email Is required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = 'Invalid email address';
  }
  if (!formValues.password) {
    errors.password = 'Password is required';
  } else if (formValues.password.length < 6) {
    errors.password = 'Please use a password that is atleast 6 characters';
  }
  if (!formValues.first_name || !formValues.last_name) {
    errors.first_name = 'Name is required';
    errors.last_name = 'Name is required';
  }
  return errors;
};

const formWrapped = reduxForm({
  form: 'registerForm',
  validate
})(RegisterForm);

export default connect(null, { registerUser })(formWrapped);
