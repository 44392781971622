import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from '../../components/Container/Container';
import CustomerAccount from '../../components/CustomerAccount/CustomerAccount';
import './MyAccountPage.scss';

class MyAccountPage extends Component {
  render() {
    return (
      <Container>
        <CustomerAccount />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    token: state.auth.token
  };
};

export default connect(mapStateToProps)(MyAccountPage);
