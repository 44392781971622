import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { trySignOut, fetchAuthStatus } from '../../actions';
import { toastr } from 'react-redux-toastr';
import {
  infoNotification,
  errorNotification
} from '../Notifications/Notifications';

class Auth extends React.Component {
  //Dropdown container ref
  dropdownContainer = React.createRef();

  //Handle state for open close dropdown
  state = {
    open: false
  };

  componentDidMount() {
    // Try to see if a cookies is present with the userId
    if (Cookies.get('customer-info')) {
      const userId = Cookies.get('customer-info');
      this.props.fetchAuthStatus(userId);
    }
    //Attatch event listener for outside of container
    document.addEventListener('mousedown', this.handleDropDownClose);
  }

  componentWillUnmount() {
    //Remove on unmount
    document.removeEventListener('mousedown', this.handleDropDownClose);
  }

  onSignOutClick = () => {
    // Try to log user out of front and backend
    const getConfirmResult = value => {
      if (value) {
        this.props.trySignOut(this.props.userId);
        infoNotification('Success!', 'You Have Been Signed Out');
      } else {
        errorNotification('Error', 'Something went wrong');
      }
    };

    const options = {
      onOk: () => getConfirmResult(true),
      onCancel: () => getConfirmResult(false)
    };

    toastr.confirm('Are You Sure', options);
  };

  //Toggle dropdown State
  handleDropDown = () => {
    this.setState(state => {
      return {
        open: !this.state.open
      };
    });
  };

  //Close dropdown on outside click
  handleDropDownClose = event => {
    if (
      this.dropdownContainer.current &&
      !this.dropdownContainer.current.contains(event.target)
    ) {
      this.setState({
        open: false
      });
    }
  };

  renderAuthButton = () => {
    if (this.props.isSignedIn !== true) {
      return (
        <>
          <li>
            <NavLink
              className={this.props.className}
              activeClassName={this.props.activeClassName}
              to='/login'
              exact
            >
              Login
            </NavLink>
          </li>
        </>
      );
    } else {
      return (
        <>
          <div
            className='navbar-dropdown-container'
            ref={this.dropdownContainer}
          >
            <li>
              <div
                className={`${this.props.className} dropdown-button`}
                onClick={this.handleDropDown}
              >
                My Account
                <i className='fas fa-caret-down' />
              </div>
            </li>
            {this.state.open && (
              <div className='navbar-dropdown'>
                <ul>
                  <li>
                    <NavLink
                      className={`${this.props.className} dropdown-link`}
                      activeClassName={this.props.activeClassName}
                      to={`/account/${this.props.userId}`}
                      exact
                    >
                      Sessions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={`${this.props.className} dropdown-link`}
                      activeClassName={this.props.activeClassName}
                      to={`/account/${this.props.userId}/forgot-password`}
                      exact
                    >
                      Forgot Password
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {/* //TODO marked as a bug redirect needs work before launch */}
          <li>
            <NavLink
              className={this.props.className}
              activeClassName={this.props.activeClassName}
              to='login'
              onClick={this.onSignOutClick}
              exact
            >
              Logout
            </NavLink>
          </li>
        </>
      );
    }
  };
  render() {
    return <>{this.renderAuthButton()}</>;
  }
}

const mapStateToProps = state => {
  return { isSignedIn: state.auth.isSignedIn, userId: state.auth.userId };
};

export default connect(mapStateToProps, { trySignOut, fetchAuthStatus })(Auth);
