import { toastr } from 'react-redux-toastr';

export const successNotification = (title, msg) => {
  return toastr.success(title, msg);
};

export const infoNotification = (title, msg) => {
  return toastr.info(title, msg);
};

export const errorNotification = (title, msg) => {
  return toastr.error(title, msg);
};
/**
 * This notification requires a callback. Maybe I can make it work here
 * //TODO see if I can find a way to use this instead of having to write it manually in each component
 */
// export const confirmNotification = (msg) => {
//   const options = {
//     onOk: () => true,
//     onCancel: () => false
//   };
//   return toastr.confirm(msg, options);
// };
