import React, { Component } from 'react';
import './AboutPage.scss';

class AboutPage extends Component {
  render() {
    return <div>About Page</div>;
  }
}

export default AboutPage;
