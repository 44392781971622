import React from 'react';
import './Logo.scss';

const Logo = () => {
  return (
    <h1 className='Logo'>
      <svg
        viewBox='0 0 24 24'
        width='24'
        height='24'
        stroke='currentColor'
        strokeWidth='2'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='logo-icon'
      >
        <path d='M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z'></path>
        <circle cx='12' cy='13' r='4'></circle>
      </svg>
      PastTime Photography
    </h1>
  );
};

export default Logo;
