import React, { Component } from 'react';
import LoginForm from '../../components/Form/LoginForm';
import Container from '../../components/Container/Container';
import './LoginPage.scss';

class LoginPage extends Component {
  render() {
    return (
      <section className='Login'>
        <div className='login-page-background' />
        <Container>
          <LoginForm />
        </Container>
      </section>
    );
  }
}

export default LoginPage;
