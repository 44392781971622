import React, { Component } from 'react';
import './ContactPage.scss';

class ContactPage extends Component {
  render() {
    return <div>ContactPage</div>;
  }
}

export default ContactPage;
