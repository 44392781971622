import backend from '../apis/backend';
import Cookies from 'js-cookie';
import history from '../history';
import {
  successNotification,
  errorNotification
} from '../components/Notifications/Notifications';

import {
  SIGN_IN,
  SIGN_OUT,
  REGISTER_USER,
  FETCH_USER_SESSIONS,
  SET_IMAGE_REQUEST,
  REMOVE_IMAGE_REQUEST,
  FETCH_USER,
  TRY_SIGN_IN,
  TRY_SIGN_OUT,
  CHANGE_AUTH,
  FETCH_AUTH_STATUS
} from './types';

//TODO clean up unused actions
export const signIn = userId => {
  return {
    type: SIGN_IN,
    payload: userId
  };
};

export const signOut = userId => {
  return {
    type: SIGN_OUT,
    payload: userId
  };
};

export const changeAuth = userId => async dispatch => {
  const response = await backend.get(
    `/items/customers/${userId}?fields=is_signed_in`
  );
  dispatch({ type: CHANGE_AUTH, payload: response.data.data.is_signed_in });
};

export const trySignIn = formValues => async dispatch => {
  const response = await backend.post(`/auth/authenticate`, formValues);
  if (response.status === 200) {
    //If response is good assing User Id
    const userId = response.data.data.user.id;
    const token = response.data.data.token;
    // set a cookie on browser with the User id
    Cookies.set('customer-info', `${userId}`);
    Cookies.set('customer-token', `${token}`);

    // set the backend to signed in
    await backend.patch(`/items/customers/${userId}`, {
      is_signed_in: 1
    });
    successNotification('Welcome', 'You Have Signed In Successfully');
    dispatch({ type: TRY_SIGN_IN, payload: response.data.data });
    history.push('/');
  } else {
    errorNotification('Oops', 'Something went wrong');
  }
};

export const trySignOut = userId => async dispatch => {
  const response = await backend.patch(`/items/customers/${userId}`, {
    is_signed_in: 0
  });
  dispatch({ type: TRY_SIGN_OUT, payload: response.data });
  Cookies.remove('customer-info');
  Cookies.remove('customer-token');
  history.push('/');
};

export const fetchAuthStatus = userId => async dispatch => {
  await backend.get(`/items/customers/${userId}`);
  dispatch({ type: FETCH_AUTH_STATUS, payload: userId });
};

export const registerUser = formValues => async dispatch => {
  let payload = { ...formValues, role: 3 };
  const response = await backend.post('/users', payload);
  if (response.status === 200) {
    successNotification(
      'Welcome',
      'Registration Successfull. Our admin will reach out to you with further instructions'
    );
    dispatch({ type: REGISTER_USER, payload: response.data });
    history.push('/');
  } else {
    errorNotification('Oops', 'Something went wrong');
  }
};

export const fetchUser = userId => async dispatch => {
  const response = await backend.get(`/users/${userId}`);
  dispatch({ type: FETCH_USER, payload: response.data });
};

// Grab the session for a specific user
export const fetchSessions = userId => async dispatch => {
  let id = userId;
  const sessionList = [];
  //TODO this call is going to hurt performance later. IT'S TOO BROAD!
  const response = await backend.get(
    '/items/session?fields=*,raw_photos.directus_files_id.*,finished_photos.directus_files_id.*'
  );
  console.log(response);
  if (response.status === 200) {
    response.data.data.map(session => {
      // normalize the type of ids for matching
      const customerId = parseInt(session.client.customer_id);
      const newId = parseInt(id);
      // Only show sessions for this user
      if (customerId === newId && session.id) {
        // a new array to the session for approval and edit requests
        sessionList.push(session);
      }
      return true;
    });
  }
  dispatch({ type: FETCH_USER_SESSIONS, payload: sessionList });
};

export const setImageRequest = (
  sessionId,
  imageId,
  currentSessions,
  type
) => dispatch => {
  /**
   *  Converts image ID to string so it can be compared with what backend has.
   *  finds the current session from all available sessions
   *  checks current session to see if it already has the ID of the requested image
   *  if false makes a post to backend and passes all sessions with the updated session to reducer
   *
   */
  const converted_id = imageId.toString();

  const sessionIndex = currentSessions
    .map(session => session.id)
    .indexOf(sessionId);

  const currentSession = currentSessions[sessionIndex];

  const currentSessionRequest =
    type === 'approve'
      ? currentSession.active_approval_requests
      : currentSession.active_edit_requests;

  const checkDuplicates = currentSessionRequest.includes(converted_id);
  if (!checkDuplicates) {
    //TODO probably need to push something backend admin understands. Not obscure ID
    currentSessionRequest.push(converted_id);
    if (type === 'approve') {
      backend.patch(`/items/session/${sessionId}`, {
        active_approval_requests: currentSessionRequest
      });
    } else {
      backend.patch(`/items/session/${sessionId}`, {
        active_edit_requests: currentSessionRequest
      });
    }

    dispatch({ type: SET_IMAGE_REQUEST, payload: currentSessions });
  }
};

export const removeImageRequest = (
  sessionId,
  imageId,
  currentSessions,
  type
) => dispatch => {
  const converted_id = imageId.toString();
  // Find the target session
  const sessionIndex = currentSessions
    .map(session => session.id)
    .indexOf(sessionId);

  const currentSession = currentSessions[sessionIndex];
  //Check to see if it is a reject or remove request
  const currentSessionRequest =
    type === 'reject'
      ? currentSession.active_approval_requests
      : currentSession.active_edit_requests;

  //Find the target image
  const imageIndex = currentSessionRequest.indexOf(converted_id);

  if (imageIndex > -1) {
    if (type === 'reject') {
      currentSessionRequest.splice(imageIndex, 1);
      backend.patch(`/items/session/${sessionId}`, {
        active_approval_requests: currentSessionRequest
      });
    } else {
      currentSessionRequest.splice(imageIndex, 1);
      backend.patch(`/items/session/${sessionId}`, {
        active_edit_requests: currentSessionRequest
      });
    }
    dispatch({ type: REMOVE_IMAGE_REQUEST, payload: currentSessions });
  }
};
