import React, { Component } from 'react';
import './PortfolioPage.scss';

class PortfolioPage extends Component {
  render() {
    return <div>PortfolioPage</div>;
  }
}

export default PortfolioPage;
