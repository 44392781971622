import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import SessionHeader from '../CustomerAccount/SessionHeader';
import {
  fetchSessions,
  setImageRequest,
  removeImageRequest
} from '../../actions';
import './Session.scss';

class Session extends Component {
  //TODO add  some loading logic because we get flicker from no session header
  //TODO this component is just TOO big. Images should probably be a seperate component
  componentDidMount() {
    //This works because the component is only mounted once this id is available
    this.props.fetchSessions(this.props.userId);
  }

  renderSessionRawImages = session => {
    //Iterate over the session to get RAW images
    if (session.raw_photos.length > 0) {
      const photo = session.raw_photos.map(photo => {
        const photoUrl = photo.directus_files_id.data.thumbnails[5].url;

        return (
          <div className='photo-gallery__item' key={photo.directus_files_id.id}>
            <div
              className='photo-gallery__content'
              onContextMenu={e => e.preventDefault()}
            >
              <img
                src={photoUrl}
                alt={photo.directus_files_id.title}
                className='session-photo'
                key={photo.directus_files_id.id}
                id={photo.directus_files_id.id}
              />
              <div className='loading'>
                <i className='fa fa-circle-o-notch fa-spin'></i>
              </div>
              <div
                className={
                  session.active_edit_requests.includes(
                    photo.directus_files_id.id.toString()
                  )
                    ? 'on-request active'
                    : 'on-request '
                }
              >
                Requested
              </div>
              <div className='photo-id'>{photo.directus_files_id.id}</div>
              <div className='photo-gallery__icon-wrapper'>
                <div className='photo-gallery__icon-content'>
                  <div className='photo-gallery__icon-content-middle'>
                    {/* View Link */}
                    <Link
                      to='#'
                      className={`tooltip view photo_${photo.directus_files_id.id}`}
                      onClick={() =>
                        this.imageClickHandler(
                          photo.directus_files_id.id,
                          'view',
                          session.id
                        )
                      }
                    >
                      <i
                        className='fa fa-search'
                        target={photo.directus_files_id.id}
                      />
                      <span className='caption caption-view'>View</span>
                    </Link>
                    {/* Request Link */}
                    <Link
                      to='#'
                      className={
                        session.active_edit_requests.includes(
                          photo.directus_files_id.id.toString()
                        )
                          ? 'tooltip request hidden'
                          : 'tooltip request '
                      }
                      onClick={() =>
                        this.imageClickHandler(
                          photo.directus_files_id.id,
                          'request',
                          session.id
                        )
                      }
                    >
                      <i
                        className='fas fa-plus'
                        target={photo.directus_files_id.id}
                      />
                      <span className='caption caption-request'>
                        Request Edit
                      </span>
                    </Link>
                    {/* Remove Request Link */}
                    <Link
                      to='#'
                      className={
                        !session.active_edit_requests.includes(
                          photo.directus_files_id.id.toString()
                        )
                          ? 'tooltip remove hidden'
                          : 'tooltip remove '
                      }
                      onClick={() =>
                        this.imageClickHandler(
                          photo.directus_files_id.id,
                          'remove',
                          session.id
                        )
                      }
                    >
                      <i
                        className='fas fa-minus'
                        target={photo.directus_files_id.id}
                      />
                      <span className='caption caption-unrequest'>
                        Remove Edit Request
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
      return photo;
    }
  };

  renderSessionFinishedImages = session => {
    //Iterate over the session to get finished photos
    const photo = session.finished_photos.map(photo => {
      const photoUrl = photo.directus_files_id.data.full_url;

      return (
        <div className='photo-gallery__item' key={photo.directus_files_id.id}>
          <div
            className='photo-gallery__content'
            onContextMenu={e => e.preventDefault()}
          >
            <img
              src={photoUrl}
              alt={photo.directus_files_id.title}
              className='session-photo'
              key={photo.directus_files_id.id}
              id={photo.directus_files_id.id}
            />
            <div className='loading'>
              <i className='fa fa-circle-o-notch fa-spin'></i>
            </div>
            <div
              className={
                session.active_approval_requests.includes(
                  photo.directus_files_id.id.toString()
                )
                  ? 'on-request active'
                  : 'on-request '
              }
            >
              Approved
            </div>
            <div className='photo-id'>{photo.directus_files_id.id}</div>
            <div className='photo-gallery__icon-wrapper'>
              <div className='photo-gallery__icon-content'>
                <div className='photo-gallery__icon-content-middle'>
                  {/* View Link */}
                  <Link
                    to='#'
                    className={`tooltip view photo_${photo.directus_files_id.id}`}
                    onClick={() =>
                      this.imageClickHandler(
                        photo.directus_files_id.id,
                        'view',
                        session.id
                      )
                    }
                  >
                    <i
                      className='fa fa-search'
                      target={photo.directus_files_id.id}
                    />
                    <span className='caption caption-view'>View</span>
                  </Link>
                  {/* Request Link */}
                  <Link
                    to='#'
                    className={
                      session.active_approval_requests.includes(
                        photo.directus_files_id.id.toString()
                      )
                        ? 'tooltip approve hidden'
                        : 'tooltip approve '
                    }
                    onClick={() =>
                      this.imageClickHandler(
                        photo.directus_files_id.id,
                        'approve',
                        session.id
                      )
                    }
                  >
                    <i
                      className='fas fa-check'
                      target={photo.directus_files_id.id}
                    />
                    <span className='caption caption-request'>Approved</span>
                  </Link>
                  {/* Remove Request Link */}
                  <Link
                    to='#'
                    className={
                      !session.active_approval_requests.includes(
                        photo.directus_files_id.id.toString()
                      )
                        ? 'tooltip reject hidden'
                        : 'tooltip reject '
                    }
                    onClick={() =>
                      this.imageClickHandler(
                        photo.directus_files_id.id,
                        'reject',
                        session.id
                      )
                    }
                  >
                    <i
                      className='fas fa-times'
                      target={photo.directus_files_id.id}
                    />
                    <span className='caption caption-unrequest'>Reject</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return photo;
  };
  renderSession = () => {
    /**
     * This handles the render of a session by calling the helpers above
     * //TODO some basic checks for data but need to flesh out more fail cases
     */
    let selectedSession;
    const { selectedOption, sessions } = this.props;
    /**
     * //TODO duplicate code in case where there is only one session
     *  there has to be a better way
     */
    if (sessions.length === 1) {
      selectedSession = sessions[0];
      return (
        <section className='session-container' key={selectedSession.id}>
          <SessionHeader showSession={true} sessionInfo={selectedSession} />
          <div className='photo-gallery raw'>
            {this.renderSessionRawImages(selectedSession)}
          </div>
          {selectedSession.finished_photos.length > 0 ? (
            <>
              <div className='photo-gallery-finished-head'>
                <h1 className='photo-gallery-finished-head__header'>
                  edited photos
                </h1>

                <p className='photo-gallery-finished-head__lead'>
                  These are your finished edits. Please review them, and either
                  approve or reject.
                </p>
              </div>
              <div className='photo-gallery finished'>
                {this.renderSessionFinishedImages(selectedSession)}
              </div>
            </>
          ) : null}
        </section>
      );
    } else if (selectedOption?.value && sessions.length > 1) {
      const selectedSessionArray = sessions.filter(
        session => session.id === selectedOption.value
      );
      selectedSession = selectedSessionArray[0];
      //Is there a session available and has fetchSession completed
      //TODO this needs to be turned into a drop down and only the selected sesson is rendered
      return (
        <section className='session-container' key={selectedSession.id}>
          <SessionHeader showSession={true} sessionInfo={selectedSession} />
          <div className='photo-gallery raw'>
            {this.renderSessionRawImages(selectedSession)}
          </div>
          {selectedSession.finished_photos.length > 0 ? (
            <>
              <div className='photo-gallery-finished-head'>
                <h1 className='photo-gallery-finished-head__header'>
                  edited photos
                </h1>

                <p className='photo-gallery-finished-head__lead'>
                  These are your finished edits. Please review them, and either
                  approve or reject.
                </p>
              </div>
              <div className='photo-gallery finished'>
                {this.renderSessionFinishedImages(selectedSession)}
              </div>
            </>
          ) : null}
        </section>
      );
    } else {
      return (
        // checking to see if loading is false and there are no sessions
        <>
          {sessions.length === 0 ? <SessionHeader showSession={false} /> : null}
        </>
      );
    }
  };

  imageClickHandler = (imageId, requestType, sessionId) => {
    const currentSessions = [...this.props.sessions];
    // add or remove the edit request on the backend
    switch (requestType) {
      //TODO can probably move all action creators into one by passing type.
      case 'request':
        this.props.setImageRequest(
          sessionId,
          imageId,
          currentSessions,
          'request'
        );
        break;
      case 'remove':
        this.props.removeImageRequest(
          sessionId,
          imageId,
          currentSessions,
          'remove'
        );
        break;
      case 'approve':
        this.props.setImageRequest(
          sessionId,
          imageId,
          currentSessions,
          'approve'
        );
        break;
      case 'reject':
        this.props.removeImageRequest(
          sessionId,
          imageId,
          currentSessions,
          'reject'
        );
        break;
      default:
        return;
    }
  };

  render() {
    return <div className='session'>{this.renderSession()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    token: state.auth.token,
    sessions: state.sessions.sessions,
    selectedSession: ownProps.selectedOption
  };
};

export default connect(mapStateToProps, {
  fetchSessions,
  setImageRequest,
  removeImageRequest
})(Session);
