import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SocialIcons from '../SocialIcons/SocialIcons';
import CopyRight from './CopyRight';
import Logo from '../Logo/Logo';

import './Footer.scss';

class Footer extends Component {
  render() {
    return (
      <footer className='Footer'>
        <div className='footer-2box'>
          <div className='footer-2box-top'>
            <div className='footer-2box-top__menu'>
              <h4>Menu</h4>
              <nav className='footer-menu-links-wrapper'>
                <ul className='footer-menu-links'>
                  <li className='footer-menu-links__link'>
                    <Link to='/'>Home</Link>
                  </li>
                  <li className='footer-menu-links__link'>
                    <Link to='/about'>About</Link>
                  </li>
                  <li className='footer-menu-links__link'>
                    <Link to='/Contact'>Contact</Link>
                  </li>
                  <li className='footer-menu-links__link'>
                    <Link to='/Portfolio'>Porfolio</Link>
                  </li>
                  <li className='footer-menu-links__link'>
                    <Link to='#'>Site Map</Link>
                  </li>
                  <li className='footer-menu-links__link'>
                    <Link to='/privacy'>Privacy Policy</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='footer-2box-top__myaccount'>
              <h4>My Account</h4>
              <nav className='footer-myaccount-links-wrapper'>
                <ul className='footer-myaccount-links'>
                  <li className='footer-myaccount-links__link'>
                    <Link to='#'>My Sessions</Link>
                  </li>
                  <li className='footer-myaccount-links__link'>
                    <Link to='#'>Forgot Password</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='footer-2box-top__contact'>
              <h4>Contact</h4>
              <div>
                Email:
                <a href='mailto: admin@pasttime.photography' type='email'>
                  <span> admin@pasttime.photography</span>
                </a>
              </div>
              <div>
                Call:
                <a href='tel:1+7702569368'>
                  <span> 770.256.9368</span>
                </a>
              </div>
            </div>
          </div>
          <div className='footer-2box-bottom'>
            <div className='footer-2box-bottom__logo'>
              <Logo />
            </div>
            <div className='footer-2box-bottom__social'>
              <SocialIcons size='2x' />
            </div>
            <div className='footer-2box-bottom__copyright'>
              <CopyRight />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
