//TODO clean up unused types
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const REGISTER_USER = 'REGISTER_USER';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_AUTH_STATUS = 'FETCH_AUTH_STATUS';
export const FETCH_USER_SESSIONS = 'FETCH_USER_SESSIONS';
export const SET_IMAGE_REQUEST = 'SET_IMAGE_REQUEST';
export const REMOVE_IMAGE_REQUEST = 'REMOVE_IMAGE_REQUEST';
export const TRY_SIGN_IN = 'TRY_SIGN_IN';
export const TRY_SIGN_OUT = 'TRY_SIGN_OUT';
export const CHANGE_AUTH = 'CHANGE_AUTH';
