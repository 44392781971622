import React from 'react';
import { NavLink } from 'react-router-dom';
import Auth from '../Auth/auth';
import './SideDrawer.scss';

const SideDrawer = props => {
  let drawerClasses = 'SideDrawer';

  if (props.show) {
    drawerClasses = 'SideDrawer open';
  }
  return (
    <nav className={drawerClasses}>
      <ul className='SideDrawer__links'>
        <li>
          <NavLink
            className='SideDrawer__link'
            activeClassName='SideDrawer__link--active'
            to='/'
            exact
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className='SideDrawer__link'
            activeClassName='SideDrawer__link--active'
            to='/about'
            exact
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            className='SideDrawer__link'
            activeClassName='SideDrawer__link--active'
            to='/contact'
            exact
          >
            Contact
          </NavLink>
        </li>
        <li>
          <NavLink
            className='SideDrawer__link'
            activeClassName='SideDrawer__link--active'
            to='/portfolio'
            exact
          >
            Portfolio
          </NavLink>
        </li>
        <Auth
          activeClassName='SideDrawer__link--active'
          className='SideDrawer__link'
        />
      </ul>
    </nav>
  );
};

export default SideDrawer;
