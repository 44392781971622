import React, { Component } from 'react';
import './ThreeBox.scss';

class ThreeBox extends Component {
  render() {
    return (
      <section className='three-box'>
        <div className='three-box-container__content'>
          <h2 className='three-box__lead'>
            WE OFFER QUITE A FEW DIFFERENT SESSION TYPES
          </h2>
          <div className='three-box__divider' />
          <p className='three-box__content'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
            ratione inventore dicta ipsa provident eaque!
          </p>
        </div>
        <div className='three-box-container__box'>
          <div className='three-box-box'>
            <picture className='three-box-image__container'>
              <img
                src='https://storage-directus.s3.amazonaws.com/storage/20a74b90-0491-4309-bfaa-5b5b4945ca34.jpg'
                alt='wedding sample'
                className='three-box-box__image'
              />
            </picture>
            <div className='three-box-box-container__content'>
              <h4 className='three-box-box__lead'>WEDDINGS</h4>
              <p className='three-box-box__content'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                quis necessitatibus facere, quo cum, omnis accusamus quisquam,
                beatae voluptatum adipisci optio? Vel cupiditate, fugiat quasi a
                odit ullam sint? Exercitationem aut architecto soluta doloribus
                eum quas temporibus, nihil aspernatur quibusdam.
              </p>
            </div>
          </div>
          <div className='three-box-box'>
            <picture className='three-box-image__container'>
              <img
                src='https://storage-directus.s3.amazonaws.com/storage/f4094882-53b0-4126-abee-dbbe4b35651a.jpg'
                alt='wedding sample'
                className='three-box-box__image'
              />
            </picture>
            <div className='three-box-box-container__content'>
              <h4 className='three-box-box__lead'>GRADUATIONS</h4>
              <p className='three-box-box__content'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                quis necessitatibus facere, quo cum, omnis accusamus quisquam,
                beatae voluptatum adipisci optio? Vel cupiditate, fugiat quasi a
                odit ullam sint? Exercitationem aut architecto soluta doloribus
                eum quas temporibus, nihil aspernatur quibusdam.
              </p>
            </div>
          </div>
          <div className='three-box-box'>
            <picture className='three-box-image__container'>
              <img
                src='https://storage-directus.s3.amazonaws.com/storage/33578454-82fa-428e-834f-e7b98b01df98.jpg'
                alt='wedding sample'
                className='three-box-box__image'
              />
            </picture>
            <div className='three-box-box-container__content'>
              <h4 className='three-box-box__lead'>FAMILY</h4>
              <p className='three-box-box__content'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                quis necessitatibus facere, quo cum, omnis accusamus quisquam,
                beatae voluptatum adipisci optio? Vel cupiditate, fugiat quasi a
                odit ullam sint? Exercitationem aut architecto soluta doloribus
                eum quas temporibus, nihil aspernatur quibusdam.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ThreeBox;
