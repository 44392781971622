import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CustomerAccount.scss';
import Landing from './Landing';
import Loading from './Loading';

class CustomerAccount extends Component {
  render() {
    const dataReady =
      this.props.userId === null && this.props.isSignedIn === null ? (
        <Loading />
      ) : (
        <Landing sessions={this.props.sessions} />
      );
    return <div className='Customer-Account'>{dataReady}</div>;
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    token: state.auth.token,
    sessions: state.sessions.sessions
  };
};

export default connect(mapStateToProps)(CustomerAccount);
