import React from 'react';
import './Card2Box.scss';
import Button from '../Button/Button';

const Card2Box = props => {
  let layout = props.layout;

  if (layout === 'reversed') {
    return (
      <section className='card-two-box'>
        <div className='card-container'>
          <div className='card-wrap card-wrap-content'>
            <div className='card card-content content__dark'>
              <div className='card-content-inner-container'>
                <div className='card-content-title__wrapper'>
                  <div className='card-subtitle'>Model / Portrait</div>
                  <div className='card-title'>
                    <h2>Spring Time Girl</h2>
                  </div>
                  <div className='card-title__break' />
                </div>
                <div className='card-content__header'>
                  <p>
                    A gray cat slinks past a wooden house. There’s something a
                    little intimidating attempting to describe
                  </p>
                </div>
                <div className='card-content__lead'>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit. Vivamus at nibh tincidunt, bibendum ligula id.
                    Nemo enim ipsam voluptatem quiatotam rem aperiam, eaque ipsa
                    quae ab illo inventore veritatis et quasi architecto beatae
                    vitae dicta sunt explicabo.
                  </p>
                </div>
                <Button className='btn-light' title='View Gallery' />
              </div>
            </div>
          </div>
          <div className='card-wrap card-wrap-image'>
            <div className='card-image-container'>
              <div className='card card-image-2' />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className='card-two-box'>
        <div className='card-container'>
          <div className='card-wrap card-wrap-image'>
            <div className='card-image-container'>
              <div className='card card-image' />
            </div>
          </div>
          <div className='card-wrap card-wrap-content'>
            <div className='card card-content'>
              <div className='card-content-inner-container'>
                <div className='card-content-title__wrapper'>
                  <div className='card-subtitle'>Model / Portrait</div>
                  <div className='card-title'>
                    <h2>Spring Time Girl</h2>
                  </div>
                  <div className='card-title__break' />
                </div>
                <div className='card-content__header'>
                  <p>
                    A gray cat slinks past a wooden house. There’s something a
                    little intimidating attempting to describe
                  </p>
                </div>
                <div className='card-content__lead'>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit. Vivamus at nibh tincidunt, bibendum ligula id.
                    Nemo enim ipsam voluptatem quiatotam rem aperiam, eaque ipsa
                    quae ab illo inventore veritatis et quasi architecto beatae
                    vitae dicta sunt explicabo.
                  </p>
                </div>
                <Button className='btn-dark' title='View Gallery' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};
export default Card2Box;
