import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { trySignIn } from '../../actions';

import './LoginForm.scss';

class LoginForm extends Component {
  renderError = ({ touched, error }) => {
    if (touched && error) {
      return (
        <div className='error'>
          <span className='error-msg'>{error}</span>
        </div>
      );
    }
  };

  renderInput = ({ input, label, type, meta }) => {
    return (
      <div className='form-name-section'>
        <input
          {...input}
          type={type}
          autoComplete='off'
          className='form-input'
          placeholder=' '
        />
        {this.renderError(meta) ? (
          <label className='form-label-name error'>
            <span className='content-name error'>{label}</span>
          </label>
        ) : (
          <label className='form-label-name'>
            <span className='content-name'>{label}</span>
          </label>
        )}
      </div>
    );
  };

  onSubmit = formValues => {
    this.props.trySignIn(formValues);
  };

  render() {
    return (
      //TODO make this reusable
      /**
       * Need to find a way to reuse this for register and forgot
       * password
       */
      <div className='login-form-container'>
        <div className='login-form-wrapper'>
          <div className='form-top'>
            <h2 className='form-top__header'>Past Time Photography</h2>
            <p className='form-top__content'>Welcome to our site</p>
          </div>
          <form
            className='Form'
            onSubmit={this.props.handleSubmit(this.onSubmit)}
          >
            <Field
              name='email'
              component={this.renderInput}
              label='Enter Email'
              type='email'
            />
            <Field
              name='password'
              component={this.renderInput}
              label='Enter Password'
              type='password'
            />
            <div className='forgot-password'>
              <Link to={'reset-password'}>
                <span className='forgot-password__text'>Forgot Password?</span>
              </Link>
            </div>
            <div className='form-bottom'>
              <button
                className='btn btn-dark primary-button'
                type='submit'
                disabled={this.props.submitting}
              >
                Sign In
              </button>
              <div className='seperator'>
                <hr className='seperator-line' />
                <span className='seperator-content'>or</span>
                <hr className='seperator-line' />
              </div>
              <div className='form-bottom-cta'>
                <p className='form-bottom-cta__content'>
                  New to Past Time Photography?
                </p>
                <Link to={'/register'}>Create Account</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const validate = formValues => {
  //TODO set timeout for all errors
  //TODO centralize errors remove logic from here
  const errors = {};
  if (!formValues.email) {
    errors.email = 'Email Is Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = 'Invalid email address';
  }
  if (!formValues.password) {
    errors.password = 'Password is required';
  } else if (formValues.password.length < 6) {
    errors.password = 'Please use a password that is atleast 6 characters';
  }
  return errors;
};

const formWrapped = reduxForm({
  form: 'loginForm',
  validate
})(LoginForm);

export default connect(null, { trySignIn })(formWrapped);
