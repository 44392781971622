import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from './authReducer';
import sessionReducer from './sessionReducer';

export default combineReducers({
  auth: authReducer,
  sessions: sessionReducer,
  form: formReducer,
  toastr: toastrReducer
});
